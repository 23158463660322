import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import AuthLayout from "../layouts/AuthLayout.vue";
import AppLayout from "../layouts/AppLayout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "dashboard" },
  },
  {
    name: "admin",
    path: "/",
    component: AppLayout,
    redirect: { name: "dashboard" },
    meta: {
      requiredAuth: true,
    },
    children: [
      {
        name: "dashboard",
        path: "dashboard",
        component: () => import("../talentum/pages/Dashboard.vue"),
      },
      {
        name: "users",
        path: "users",
        component: () => import("../talentum/pages/UsersPage.vue"),
      },
      {
        name: "user",
        path: "users/:id",
        component: () => import("../talentum/pages/UserPage.vue"),
      },

      {
        name: "categories",
        path: "categories",
        component: () => import("../talentum/pages/CategoriesPage.vue"),
      },
      {
        name: "nft-collections",
        path: "nft-collections",
        component: () => import("../talentum/pages/NftCollectionsPage.vue"),
      },
      {
        name: "nfts",
        path: "nfts",
        component: () => import("../talentum/pages/NftsPage.vue"),
      },
      {
        name: "blog",
        path: "blog",
        component: () => import("../talentum/pages/BlogPage.vue"),
      },
      {
        name: "lootboxes",
        path: "lootboxes",
        component: () => import("../talentum/pages/LootboxesPage.vue"),
      },
      {
        name: "crypto",
        path: "crypto",
        component: () => import("../talentum/pages/CryptoCurrencies.vue"),
      },
      {
        name: "crypto-deposits",
        path: "deposits",
        component: () => import("../talentum/pages/DepositsPage.vue"),
      },
      {
        name: "settings",
        path: "settings",
        component: () => import("../talentum/pages/SettingsPage.vue"),
      },
      {
        name: "games",
        path: "games",
        component: () => import("../talentum/pages/GamesPage.vue"),
      },
    ],
  },
  {
    path: "/auth",
    component: AuthLayout,
    children: [
      {
        name: "login",
        path: "login",
        component: () => import("../pages/auth/Login.vue"),
      },
      {
        path: "",
        redirect: { name: "login" },
      },
    ],
  },
  {
    name: "404",
    path: "/404",
    component: () => import("../pages/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      window.scrollTo(0, 0);
    }
  },
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");

  if (to.meta.requiredAuth && !token) {
    next("/auth/login");
  }

  next();
});

export default router;
